import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SocialIcons from "./components/utils/SocialIcons";
import CookieConsent from "./components/utils/CookieConsent";
import MotionDiv from "./components/utils/MotionDiv";
import Snowflakes from "./components/SnowFlakes";

// Lazy-loaded page components
const pages = {
  Home: lazy(() => import("./pages/HomePage")),
  Games: lazy(() => import("./pages/GamesPage")),
  Aup: lazy(() => import("./pages/AupPage")),
  Partners: lazy(() => import("./pages/PartnersPage")),
  Privacy: lazy(() => import("./pages/PrivacyPolicy")),
  AppHosting: lazy(() => import("./pages/AppHostingPage")),
  Terms: lazy(() => import("./pages/TermsOfService")),
  Minecraft: lazy(() => import("./pages/MinecraftPage")),
  CounterStrike: lazy(() => import("./pages/CounterStrikePage")),
  GarrysMod: lazy(() => import("./pages/GarrysModPage")),
  Palworld: lazy(() => import("./pages/PalworldPage")),
  Hardware: lazy(() => import("./pages/HardwarePage")),
  About: lazy(() => import("./pages/AboutUsPage")),
  Jobs: lazy(() => import("./pages/JobsPage")),
  Rust: lazy(() => import("./pages/RustPage")),
  SurvivalEvolved: lazy(() => import("./pages/SurvivalEvolved")),
  Satisfactory: lazy(() => import("./pages/SatisfactoryPage")),
  NotFound: lazy(() => import("./pages/NotFound"))
};

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="w-8 h-8 border-4 border-virtue-primary-green rounded-full border-t-transparent animate-spin" />
  </div>
);

const PageTransition = ({ children }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div className="page-wrapper">
      {isLoading && <LoadingSpinner />}
      <div
        className={`transition-opacity duration-300 ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

// Route configurations
const routes = [
  { path: "/", element: <pages.Home /> },
  { path: "/games", element: <pages.Games /> },
  { path: "/aup", element: <pages.Aup /> },
  { path: "/partners", element: <pages.Partners /> },
  { path: "/privacy-policy", element: <pages.Privacy /> },
  { path: "/app-hosting", element: <pages.AppHosting /> },
  { path: "/terms-of-service", element: <pages.Terms /> },
  { path: "/minecraft", element: <pages.Minecraft /> },
  { path: "/counterstrike", element: <pages.CounterStrike /> },
  { path: "/garrysmod", element: <pages.GarrysMod /> },
  { path: "/palworld", element: <pages.Palworld /> },
  { path: "/hardware", element: <pages.Hardware /> },
  { path: "/about", element: <pages.About /> },
  { path: "/jobs", element: <pages.Jobs /> },
  { path: "/rust", element: <pages.Rust /> },
  { path: "/survival-evolved", element: <pages.SurvivalEvolved /> },
  { path: "/satisfactory", element: <pages.Satisfactory /> },
  { path: "*", element: <pages.NotFound /> }
];

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Snowflakes /> {/* Moved here - outside PageTransition but inside BrowserRouter */}
        <main className="flex-grow bg-virtue-background">
          <Navbar />
          <MotionDiv />
          <PageTransition>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                {routes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              </Routes>
            </Suspense>
          </PageTransition>
        </main>
        <SocialIcons />
        <CookieConsent />
        <Footer />
      </div>
    </BrowserRouter>
  </HelmetProvider>
);
export default App;