import React, { useEffect, useRef, useCallback } from "react";

const DEFAULT_COLORS = {
  from: "#22C55E",
  via: "#22C55E",
  to: "#22C55E"
};

function ScrollProgressBar({
  height = "2px",
  colors = DEFAULT_COLORS,
  ariaLabel = "Page scroll progress"
}) {
  const progressRef = useRef(null);
  const glowRef = useRef(null);

  const updateProgress = useCallback(() => {
    if (!progressRef.current) return;

    const winScroll = document.documentElement.scrollTop || document.body.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = Math.min(Math.max((winScroll / height) * 100, 0), 100);

    progressRef.current.style.width = `${scrolled}%`;
    progressRef.current.setAttribute('aria-valuenow', Math.round(scrolled));

    if (glowRef.current) {
      const glowOpacity = 0.2 + (scrolled / 100) * 0.2;
      glowRef.current.style.opacity = String(glowOpacity);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', updateProgress, { passive: true });
    updateProgress();
    return () => window.removeEventListener('scroll', updateProgress);
  }, [updateProgress]);

  return (
    <>
      {/* Background track */}
      <div 
        className="fixed top-0 left-0 right-0 z-40"
        style={{ height }}
        role="presentation"
      >
        <div className="relative w-full h-full">
          <div
            className="absolute inset-0 bg-slate-800/90"
            style={{ borderRadius: height }}
          />
          
          <div
            className="absolute inset-0 overflow-hidden"
            style={{ borderRadius: height }}
          >
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-slate-700/30"
                style={{
                  top: `${20 * i}%`,
                  left: 0,
                  right: 0,
                  transform: `translateX(${Math.random() * 100}%)`
                }}
                aria-hidden="true"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Progress bar */}
      <div
        ref={progressRef}
        className="fixed top-0 left-0 z-50 h-full origin-left motion-safe:transition-all motion-safe:duration-150 motion-safe:ease-out"
        style={{
          height,
          width: "0%",
          background: `linear-gradient(to right, ${colors.from}, ${colors.via}, ${colors.to})`,
          borderRadius: height
        }}
        role="progressbar"
        aria-label={ariaLabel}
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow="0"
      >
        {/* Gradient overlay */}
        <div
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(90deg, transparent 30%, rgba(255,255,255,0.1), transparent 70%)',
            borderRadius: height
          }}
          aria-hidden="true"
        />

        {/* Glitch effect */}
        <div 
          className="absolute inset-0 overflow-hidden"
          aria-hidden="true"
        >
          <div
            className="absolute inset-0 motion-safe:animate-[glitch_1.5s_linear_infinite_alternate]"
            style={{
              backgroundImage: `
                repeating-linear-gradient(
                  90deg,
                  transparent,
                  transparent 20px,
                  rgba(255,255,255,0.1) 20px,
                  rgba(255,255,255,0.1) 21px
                )
              `
            }}
          />
        </div>

        {/* Glow effect */}
        <div
          ref={glowRef}
          className="absolute right-0 h-full"
          style={{
            width: '8px',
            background: colors.to,
            filter: 'blur(2px)',
            opacity: 0.2,
            borderRadius: height
          }}
          aria-hidden="true"
        />

        {/* Scanner line */}
        <div
          className="absolute top-0 right-0 h-full w-20 motion-safe:animate-[scan_2s_linear_infinite]"
          style={{
            background: `linear-gradient(90deg, transparent, ${colors.to}20, transparent)`
          }}
          aria-hidden="true"
        />
      </div>

      {/* Keyframes */}
      <style>
        {`
          @keyframes glitch {
            0% { background-position: 0% 0%; }
            100% { background-position: 100% 0%; }
          }

          @keyframes scan {
            0% { transform: translateX(0); }
            50% { transform: translateX(-80px); }
            100% { transform: translateX(0); }
          }
        `}
      </style>
    </>
  );
}

export default ScrollProgressBar;