import React, { useState, useEffect } from "react";
import { ChevronRight } from "lucide-react";

const socialLinks = [
  {
    href: "https://discord.com/invite/UR5BD98Wn6",
    icon: (
      <svg viewBox="0 0 24 24" className="w-6 h-6" style={{ color: "#7289DA" }}>
        <path
          fill="currentColor"
          d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"
        />
      </svg>
    ),
    label: "Join our Discord server",
  },
  {
    href: "https://www.instagram.com/virtuehost/",
    icon: (
      <svg viewBox="0 0 24 24" className="w-6 h-6" style={{ color: "#c13584" }}>
        <path
          fill="currentColor"
          d="M12 2.163c3.204 0 3.584.012 4.85.07c3.252.148 4.771 1.691 4.919 4.919c.058 1.265.069 1.645.069 4.849c0 3.205-.012 3.584-.069 4.849c-.149 3.225-1.664 4.771-4.919 4.919c-1.266.058-1.644.07-4.85.07c-3.204 0-3.584-.012-4.849-.07c-3.26-.149-4.771-1.699-4.919-4.92c-.058-1.265-.07-1.644-.07-4.849c0-3.204.013-3.583.07-4.849C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163M12 0C8.741 0 8.333.014 7.053.072c-4.358.2-6.78 2.618-6.98 6.98C.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948c.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072c4.354-.2 6.782-2.618 6.979-6.98C23.986 15.668 24 15.259 24 12c0-3.259-.014-3.667-.072-4.947c-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0m0 5.838a6.162 6.162 0 1 0 0 12.324a6.162 6.162 0 0 0 0-12.324M12 16a4 4 0 1 1 0-8a4 4 0 0 1 0 8m6.406-11.845a1.44 1.44 0 1 0 0 2.881a1.44 1.44 0 0 0 0-2.881"
        />
      </svg>
    ),
    label: "Follow us on Instagram",
  },
  {
    href: "https://www.x.com/virtuehosting",
    icon: (
      <svg viewBox="0 0 24 24" className="w-6 h-6" style={{ color: "#1DA1F2" }}>
        <path
          fill="currentColor"
          d="M18.244 2.25h3.308l-7.227 8.26l8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
        />
      </svg>
    ),
    label: "Follow us on X",
  },
  /* {
    href: "https://www.youtube.com/@virtuehost",
    icon: (
      <svg viewBox="0 0 24 24" className="w-5 h-5" style={{ color: "#FF0000" }}>
        <path fill="currentColor" d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
      </svg>
    ),
    label: "Subscribe to our YouTube channel",
  */
];

const SocialIcon = ({ href, icon, label, index, position }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    className="p-3 rounded-xl hover:bg-virtue-surface transition-all duration-200 relative group animate-fadeSlide"
    style={{
      animationDelay: `${index * 50}ms`,
      animationFillMode: "forwards",
    }}
  >
    <div className="transform transition-transform duration-200 group-hover:scale-110 drop-shadow-lg">
      {icon}
    </div>

    <span
      className={`absolute ${
        position === "right" ? "right-full mr-2" : "left-full ml-2"
      } 
                   px-2 py-1 bg-virtue-surface border-virtue-border text-sm text-gray-300 rounded-md whitespace-nowrap
                   pointer-events-none hidden md:block opacity-0 group-hover:opacity-100
                   translate-y-1 group-hover:translate-y-0 transition-all duration-200`}
    >
      {label}
    </span>
  </a>
);

const SocialIcons = ({ position = "right" }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const checkMobile = () => window.innerWidth < 768;
    setIsMobile(checkMobile());

    if (checkMobile()) {
      const storedState = localStorage.getItem("socialIconsOpen");
      setIsOpen(storedState === null ? true : storedState === "true");
    }

    const handleResize = () => {
      const wasMobile = isMobile;
      const nowMobile = checkMobile();

      if (wasMobile !== nowMobile) {
        setIsMobile(nowMobile);
        if (!nowMobile) {
          setIsOpen(true);
        } else {
          const storedState = localStorage.getItem("socialIconsOpen");
          setIsOpen(storedState === null ? true : storedState === "true");
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (isMobile) {
      localStorage.setItem("socialIconsOpen", String(newState));
    }
  };

  return (
    <>
      <div
        className={`fixed ${
          position === "right" ? "right-0" : "left-0"
        } top-1/3 z-40 transition-transform duration-300
                    ${
                      !isOpen
                        ? position === "right"
                          ? "translate-x-full"
                          : "-translate-x-full"
                        : "translate-x-0"
                    }`}
      >
        <div className="relative flex items-center">
          <button
            onClick={handleToggle}
            className={`md:hidden absolute ${
              position === "right" ? "-left-7" : "-right-7"
            }
              top-1/2 -translate-y-1/2 bg-virtue-card p-2
              ${position === "right" ? "rounded-l-lg" : "rounded-r-lg"}
              shadow-lg border border-virtue-border
              transition-colors duration-200 hover:bg-slate-700`}
            aria-label={isOpen ? "Hide social links" : "Show social links"}
          >
            <div
              className={`transition-transform duration-200 ${
                isOpen ? "rotate-0" : "rotate-180"
              }`}
            >
              <ChevronRight
                className={`w-4 h-4 text-gray-400 ${
                  position === "right" ? "" : "rotate-180"
                }`}
              />
            </div>
          </button>

          <div
            className="flex flex-col gap-2 bg-virtue-card backdrop-blur p-3
                       border border-virtue-border
                       shadow-xl rounded-lg md:rounded-xl transition-colors duration-200"
          >
            {socialLinks.map((link, index) => (
              <SocialIcon
                key={link.href}
                {...link}
                index={index}
                position={position}
              />
            ))}
          </div>
        </div>
      </div>

      <style>
        {`
          @keyframes fadeSlide {
            from {
              opacity: 0;
              transform: translateX(20px);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-fadeSlide {
            animation: fadeSlide 0.2s ease-out;
            opacity: 0;
          }
        `}
      </style>
    </>
  );
};

export default SocialIcons;
