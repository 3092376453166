import React, { memo, useState, useEffect } from "react";
import { Cookie } from "lucide-react";

const STORAGE_KEY = "cookieConsent";
const GA_TRACKING_ID = "G-ZBC28NF6RQ";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const consent = getStoredConsent();

    if (!consent) {
      setIsVisible(true);
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    } else if (consent.acceptAnalytics) {
      initializeAnalytics();
    }
  }, []);

  const getStoredConsent = () => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      return stored ? JSON.parse(stored) : null;
    } catch (error) {
      console.error("Error reading cookie consent:", error);
      return null;
    }
  };

  const handleConsent = (accepted) => {
    try {
      const consentData = {
        acceptAnalytics: accepted,
        timestamp: Date.now(),
      };

      localStorage.setItem(STORAGE_KEY, JSON.stringify(consentData));
      setIsVisible(false);

      if (accepted) {
        initializeAnalytics();
      }
    } catch (error) {
      console.error("Error storing cookie consent:", error);
    }
  };

  const initializeAnalytics = () => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };

      window.gtag("js", new Date());
      window.gtag("config", GA_TRACKING_ID, {
        anonymize_ip: true,
        cookie_expires: 365 * 24 * 60 * 60, // 1 year in seconds
      });
    } catch (error) {
      console.error("Error initializing analytics:", error);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 bg-virtue-card backdrop-blur-sm border-t border-virtue-border shadow-lg animate-slide-up">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          {/* Icon and Text */}
          <div className="flex items-center flex-1">
            <div className="flex-shrink-0 p-2 rounded-lg bg-green-500/10 border border-green-500/20">
              <Cookie className="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <p
              className={`ml-3 font-medium text-white ${
                isMobile ? "text-sm" : "text-base"
              }`}
            >
              We use cookies and similar technologies to ensure you get the best
              experience on our website.{" "}
              <a
                href="/privacy-policy"
                className="text-green-400 hover:text-green-300 transition-colors duration-200 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900 focus:ring-green-500"
              >
                Learn more
              </a>
            </p>
          </div>

          {/* Buttons */}
          <div className="flex items-center gap-3">
            <button
              onClick={() => handleConsent(false)}
              className="px-4 py-2 border border-virtue-primary-green/30 rounded-lg text-sm font-medium text-gray-300
                bg-transparent hover:bg-slate-700 hover:border-slate-600
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900 focus:ring-slate-700
                transition-all duration-200"
            >
              Decline
            </button>
            <button
              onClick={() => handleConsent(true)}
              className="px-4 py-2 bg-virtue-primary-green/50 hover:bg-green-600 rounded-lg text-sm font-medium text-white
                shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900 focus:ring-green-500
                transition-all duration-200"
            >
              Accept
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideUp {
          from {
            transform: translateY(100%);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .animate-slide-up {
          animation: slideUp 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }
      `}</style>
    </div>
  );
}
export default memo(CookieConsent);
