import React, { useState, useRef, useEffect } from "react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
  HomeIcon,
  RocketLaunchIcon,
  CommandLineIcon,
  BuildingOfficeIcon,
  ChartBarIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  ComputerDesktopIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

const PromoBanner = () => {
  return (
    <div className="w-full bg-virtue-navigation/50 relative overflow-hidden border-b border-cyan-900/20">
      <div className="hidden md:block max-w-7xl mx-auto px-4 py-3 relative">
        <div className="flex items-center justify-between gap-6 relative z-10">
          <div className="flex items-center mx-auto gap-4">
            <span className="text-white font-medium whitespace-nowrap">
              Get 50% OFF your first month with promocode:{" "}
              <span className="text-alertnotice font-bold">
                <code className="bg-virtue-card border border-virtue-border py-1 px-2 rounded-xl">
                  NEWYEAR50
                </code>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const NAV_ITEMS = [
  { label: "Home", href: "/", icon: HomeIcon },
  { label: "Game Servers", href: "/games", icon: RocketLaunchIcon },
  {
    label: "Solutions",
    icon: CommandLineIcon,
    items: [
      { label: "App Hosting", href: "/app-hosting", icon: CommandLineIcon },
    ],
  },
  {
    label: "Company",
    icon: BuildingOfficeIcon,
    items: [
      {
        label: "Server Status",
        href: "https://status.virtue-host.com",
        icon: ChartBarIcon,
      },
      {
        label: "Terms of Service",
        href: "/terms-of-service",
        icon: DocumentTextIcon,
      },
      {
        label: "Privacy Policy",
        href: "/privacy-policy",
        icon: ShieldCheckIcon,
      },
    ],
  },
];

const NavLink = ({ href, children, icon: Icon, external }) => (
  <a
    href={href}
    className="flex items-center px-4 py-2 text-gray-300 hover:text-white rounded-lg transition-all duration-300 group
               hover:bg-virtue-card/50 backdrop-blur-sm"
    {...(external && { target: "_blank", rel: "noopener noreferrer" })}
  >
    {Icon && (
      <Icon className="h-4 w-4 mr-2.5 text-virtue-primary-green group-hover:text-green-400 transition-colors" />
    )}
    <span className="relative font-medium">{children}</span>
    {external && (
      <ArrowTopRightOnSquareIcon className="ml-1.5 h-4 w-4 opacity-75" />
    )}
  </a>
);

const Dropdown = ({ label, items, icon: Icon, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => setIsOpen(false), 150);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      className={isMobile ? "" : "relative"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={() => isMobile && setIsOpen(!isOpen)}
        className="flex items-center w-full px-4 py-2 text-gray-300 hover:text-white rounded-lg transition-all duration-300 group
                   hover:bg-virtue-card/50 backdrop-blur-sm"
      >
        {Icon && (
          <Icon className="h-4 w-4 mr-2.5 text-virtue-primary-green group-hover:text-green-400 transition-colors" />
        )}
        <span className="flex-1 text-left font-medium">{label}</span>
        <ChevronDownIcon
          className={`h-4 w-4 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div
          className={`
          ${
            isMobile
              ? "pl-4 py-2 space-y-1"
              : "absolute top-full right-0 mt-1 p-2 bg-virtue-card/95 rounded-xl border border-virtue-border shadow-xl backdrop-blur-sm min-w-[200px] space-y-1"
          }`}
        >
          {items.map((item, idx) => (
            <NavLink
              key={idx}
              href={item.href}
              icon={item.icon}
              external={item.href.startsWith("http")}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

const ClientAreaButton = ({ isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => setIsOpen(false), 150);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  if (isMobile) {
    return (
      <div className="px-3 py-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center w-full px-4 py-2 rounded-lg text-white font-medium transition-all duration-300 bg-virtue-primary-green hover:bg-virtue-secondary-green/50 shadow-lg shadow-virtue-primary-green/10"
        >
          <UserIcon className="h-4 w-4 mr-2.5 text-white" />
          <span className="flex-1 text-left">Sign In</span>
          <ChevronDownIcon
            className={`h-4 w-4 transition-transform duration-300 ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
        {isOpen && (
          <div className="mt-2 space-y-1 rounded-lg border border-virtue-border bg-virtue-card/95 p-2 backdrop-blur-sm">
            <NavLink
              href="https://billing.virtue-host.com/"
              icon={UserIcon}
              external={true}
            >
              Client Area
            </NavLink>
            <NavLink
              href="https://panel.virtue-host.com/"
              icon={ComputerDesktopIcon}
              external={true}
            >
              Game Panel
            </NavLink>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300 hover:scale-105 bg-virtue-primary-green text-white hover:bg-virtue-secondary-green/50 shadow-lg shadow-virtue-primary-green/10">
        <UserIcon className="h-4 w-4 mr-2 text-white" />
        Sign In
        <ChevronDownIcon
          className={`h-4 w-4 ml-2 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute top-full right-0 mt-1 p-2 bg-virtue-card/95 rounded-xl border border-virtue-border shadow-xl backdrop-blur-sm min-w-[200px] space-y-1">
          <NavLink
            href="https://billing.virtue-host.com/"
            icon={UserIcon}
            external={true}
          >
            Client Area
          </NavLink>
          <NavLink
            href="https://panel.virtue-host.com/"
            icon={ComputerDesktopIcon}
            external={true}
          >
            Game Panel
          </NavLink>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="sticky top-0 z-50 bg-virtue-navigation/50 backdrop-blur-md border-b border-virtue-border">
      <PromoBanner />
      <div className="absolute inset-0 opacity-30">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[size:64px_64px]" />
        <div className="absolute inset-0 bg-[linear-gradient(to_bottom,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[size:64px_64px]" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <a
            href="/"
            className="group flex items-center space-x-3 transition-transform duration-300 hover:scale-105"
          >
            <div className="relative">
              <img
                src="/images/company/logo.png"
                alt="Logo"
                className="h-auto w-10 rounded-lg shadow-lg ring-1 ring-black/10"
                width={32}
                height={32}
              />
            </div>
            <div>
              <div className="text-xl font-bold text-white">Virtue Host</div>
              <div className="text-xs text-virtue-primary-green font-medium">
                Ryzen Game Servers
              </div>
            </div>
          </a>

          <div className="hidden lg:flex lg:items-center lg:space-x-1">
            {NAV_ITEMS.map((item, idx) =>
              item.items ? (
                <Dropdown key={idx} {...item} />
              ) : (
                <NavLink key={idx} href={item.href} icon={item.icon}>
                  {item.label}
                </NavLink>
              )
            )}
          </div>

          <div className="hidden lg:flex lg:items-center lg:space-x-3">
            <ClientAreaButton />
          </div>

          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="lg:hidden p-2 rounded-lg text-gray-400 hover:text-white hover:bg-virtue-card/50 transition-colors"
          >
            {mobileMenuOpen ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <Bars3Icon className="h-5 w-5" />
            )}
          </button>
        </div>

        {mobileMenuOpen && (
          <div className="lg:hidden py-4">
            <div className="space-y-1">
              {NAV_ITEMS.map((item, idx) =>
                item.items ? (
                  <Dropdown key={idx} {...item} isMobile />
                ) : (
                  <NavLink key={idx} href={item.href} icon={item.icon}>
                    {item.label}
                  </NavLink>
                )
              )}
            </div>
            <ClientAreaButton isMobile />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
