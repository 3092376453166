import React, { memo } from 'react';

// Memoize the component to prevent unnecessary re-renders
const Snowflakes = memo(() => {
  const snowflakes = Array.from({ length: 20 }, (_, i) => ({
    id: i,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 10}s`,
    animationDuration: `${15 + Math.random() * 15}s`,
    scale: 0.3 + Math.random() * 0.7,
    type: Math.random() > 0.5 ? '❄' : '❅'
  }));

  return (
    <div 
      className="fixed left-0 top-0 w-full h-full pointer-events-none" 
      style={{ 
        zIndex: 50,
        willChange: 'transform' // Optimize performance
      }}
    >
      {snowflakes.map(({ id, left, animationDelay, animationDuration, scale, type }) => (
        <div
          key={id}
          className="absolute top-0 text-white opacity-0"
          style={{
            left,
            animation: `snowfall-${id} ${animationDuration} linear infinite, fade-in 2s ease-out forwards`,
            animationDelay,
            transform: `scale(${scale})`,
            fontSize: '16px',
            opacity: 0.4 + (Math.random() * 0.2),
            willChange: 'transform'
          }}
        >
          {type}
        </div>
      ))}

      <style>{`
        @keyframes fade-in {
          from { opacity: 0; }
          to { opacity: 0.4; }
        }
        
        ${snowflakes.map(({ id }) => `
          @keyframes snowfall-${id} {
            0% {
              transform: translateY(-10px) rotate(0deg) translate(0, 0);
            }
            50% {
              transform: translateY(50vh) rotate(180deg) translate(${-20 + Math.random() * 40}px, 0);
            }
            100% {
              transform: translateY(105vh) rotate(360deg) translate(0, 0);
            }
          }`).join('\n')}
      `}</style>
    </div>
  );
});

Snowflakes.displayName = 'Snowflakes';

export default Snowflakes;