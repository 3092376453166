import React from "react";
import {
  UserIcon,
  BuildingOfficeIcon,
  LinkIcon,
  DocumentTextIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { BoxIcon } from "lucide-react";

const FOOTER_DATA = {
  sections: [
    {
      title: "Services",
      icon: BoxIcon,
      links: [
        { text: "Minecraft Servers", href: "/minecraft" },
        { text: "Game Servers", href: "/games" },
        { text: "App Hosting", href: "/app-hosting" },
      ],
    },
    {
      title: "Clients",
      icon: UserIcon,
      links: [
        {
          text: "Game Panel",
          href: "https://panel.virtue-host.com/",
          isExternal: true,
        },
        {
          text: "Client Area",
          href: "https://billing.virtue-host.com/",
          isExternal: true,
        },
        {
          text: "Create a Ticket",
          href: "https://billing.virtue-host.com/submitticket.php/",
          isExternal: true,
        },
        {
          text: "Knowledgebase",
          href: "https://billing.virtue-host.com/knowledgebase/",
          isExternal: true,
        },
      ],
    },
    {
      title: "Company",
      icon: BuildingOfficeIcon,
      links: [
        {
          text: "Network Status",
          href: "https://status.virtue-host.com/",
          isExternal: true,
        },
        { text: "Our Story", href: "/about" },
        {
          text: "Contact Us",
          href: "https://billing.virtue-host.com/contact.php",
          isExternal: true,
        },
        { text: "Hardware Details", href: "/hardware" },
      ],
    },
    {
      title: "Resources",
      icon: LinkIcon,
      links: [
        {
          text: "Affiliate Program",
          href: "https://billing.virtue-host.com/affiliates.php",
          isExternal: true,
        },
        { text: "Jobs", href: "/jobs" },
        { text: "Our Partners", href: "/partners" },
      ],
    },
    {
      title: "Legal",
      icon: DocumentTextIcon,
      links: [
        { text: "Terms of Service", href: "/terms-of-service" },
        { text: "Privacy Policy", href: "/privacy-policy" },
        { text: "Acceptable Use Policy", href: "/aup" },
      ],
    },
  ],
  social: [
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          className="w-5 h-5 fill-current"
          aria-hidden="true"
          role="img"
        >
          <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z" />
        </svg>
      ),
      href: "https://discord.com/invite/UR5BD98Wn6",
      label: "Join our Discord community",
    },
  ],
};

function FooterLink({ href, text, icon, label, isExternal, className }) {
  return (
    <a
      href={href}
      className={`group inline-flex items-center ${className} 
                  rounded-lg transition-all duration-300`}
      target={isExternal ? "_blank" : undefined}
      rel={isExternal ? "noopener noreferrer" : undefined}
      aria-label={
        label || (isExternal ? `${text} (opens in new tab)` : undefined)
      }
    >
      {text && (
        <span className="relative">
          {text}
          <span
            className="absolute -bottom-0.5 left-0 w-0 h-0.5 bg-virtue-primary-green/50 transition-all duration-300 group-hover:w-full"
            aria-hidden="true"
          />
        </span>
      )}
      {icon}
      {isExternal && text && (
        <ArrowTopRightOnSquareIcon
          className="ml-1 inline-block w-3 h-3 opacity-0 -translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300"
          aria-hidden="true"
        />
      )}
    </a>
  );
}

function FooterSection({ title, icon: Icon, links }) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-6 group">
        <div className="relative mr-3">
          <div
            className="w-10 h-10 rounded-lg bg-virtue-card border border-virtue-border group-hover:border-virtue-primary-green/50 
                        flex items-center justify-center text-virtue-primary-green transition-all duration-300"
          >
            <Icon className="w-5 h-5 transition-transform duration-300 group-hover:scale-110" />
          </div>
          <div className="absolute inset-0 rounded-lg bg-virtue-primary-green/5 blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </div>
        <h3 className="text-lg font-semibold text-white group-hover:text-virtue-primary-green transition-colors duration-300">
          {title}
        </h3>
      </div>

      <div
        className="flex flex-col space-y-3"
        role="list"
        aria-label={`${title} links`}
      >
        {links.map((link, i) => (
          <div key={`${title}-link-${i}`} role="listitem">
            <FooterLink
              {...link}
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-all duration-300 hover:translate-x-1"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="relative bg-virtue-navigation border-t border-virtue-border"
      role="contentinfo"
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-30">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[size:64px_64px]" />
        <div className="absolute inset-0 bg-[linear-gradient(to_bottom,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[size:64px_64px]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-virtue-navigation to-virtue-navigation" />
      </div>

      <div className="relative container mx-auto px-6 py-16 max-w-7xl">
        <nav
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12"
          aria-label="Footer navigation"
        >
          {FOOTER_DATA.sections.map((section) => (
            <FooterSection key={section.title} {...section} />
          ))}
        </nav>

        <div className="mt-12 pt-8 border-t border-virtue-border">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
            <div className="flex items-center gap-4 group">
              <div className="relative">
                <img
                  src="/images/company/logo.png"
                  alt="Virtue Host Logo"
                  className="w-8 h-8 rounded-lg ring-1 ring-virtue-border group-hover:ring-virtue-primary-green/50 transition-all duration-300"
                  width={32}
                  height={32}
                  loading="lazy"
                  decoding="async"
                />
                <div className="absolute inset-0 rounded-lg bg-virtue-primary-green/5 blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-sm">
                  © {currentYear} Virtue Host. All rights reserved.
                </p>
                <p className="text-gray-500 text-xs group-hover:text-virtue-primary-green transition-colors duration-300">
                  Experience Virtue. Elevate Hosting.
                </p>
              </div>
            </div>

            <div
              className="flex items-center gap-4"
              role="list"
              aria-label="Social media links"
            >
              {FOOTER_DATA.social.map((link, index) => (
                <div key={`social-link-${index}`} role="listitem">
                  <FooterLink
                    {...link}
                    className="p-2.5 text-gray-400 hover:text-virtue-primary-green bg-virtue-card/50 hover:bg-virtue-card 
                              border border-virtue-border hover:border-virtue-primary-green/50 rounded-lg
                              transition-all duration-300 hover:scale-110 hover:rotate-3"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
